import React from 'react'

export default function Footer({theme}) {
    
    return (
        <div>
            <footer className={`border-t  py-10 ${theme === 'dark' ? 'bg-black text-white border-white/20' : 'bg-white text-black border-gray-200'}`}>
                <div className="w-[90%] mx-auto">
                    <div className="flex flex-col lg:flex-row gap-5 lg:gap-8">
                        <div className="space-y-4">
                            <h3 className="text-lg font-bold ">OzoneX</h3>
                            <p className={`text-sm ${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}>
                                The world's first and largest digital marketplace for crypto collectibles and non-fungible tokens (NFTs). Buy, sell, and discover exclusive digital items.
                            </p>
                            <div className="flex space-x-4 text-white">
                                <a href="#" className="bg-[#019444] w-10 h-10 flex items-center justify-center rounded-full">
                                    <i className="fab fa-whatsapp text-2xl"></i>
                                </a>
                                <a href="#" className="bg-[#019444] w-10 h-10 flex items-center justify-center rounded-full">
                                    <i className="fab fa-x-twitter text-2xl"></i>
                                </a>
                                <a href="#" className="bg-[#019444] w-10 h-10 flex items-center justify-center rounded-full">
                                    <i className="fab fa-linkedin-in text-2xl"></i>
                                </a>
                                <a href="#" className="bg-[#019444] w-10 h-10 flex items-center justify-center rounded-full">
                                    <i className="fab fa-instagram text-2xl"></i>
                                </a>
                            </div>
                        </div>
                        <div className={`grid grid-cols-2 md:grid-cols-4 gap-5 lg:gap-0 ${theme === 'dark' ? 'text-gray-300' : 'text-gray-600'}`}>
                            <div className='ml-3 lg:ml-0'>
                                <h3 className={`text-lg font-bold ${theme === 'dark' ? 'text-white' : 'text-black'}`}>Resources</h3>
                                <ul className="mt-4 space-y-2 text-sm list-disc marker:text-[#019444] ">
                                    <li>
                                        <a href="#" className="hover:text-[#019444] ">
                                            Blog
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className="hover:text-[#019444] ">
                                            Help Center
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className="hover:text-[#019444] ">
                                            Community Center
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div className='ml-3 lg:ml-0'>
                                <h3 className={`text-lg font-bold ${theme === 'dark' ? 'text-white' : 'text-black'}`}>Company</h3>
                                <ul className="mt-4 space-y-2 text-sm list-disc marker:text-[#019444] ">
                                    <li>
                                        <a href="#" className="hover:text-[#019444] ">
                                            About Us
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className="hover:text-[#019444] ">
                                            Ozone Space
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className="hover:text-[#019444] ">
                                            Helpful Links
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div className='ml-3 lg:ml-0'>
                                <h3 className={`text-lg font-bold ${theme === 'dark' ? 'text-white' : 'text-black'}`}>Help</h3>
                                <ul className="mt-4 space-y-2 text-sm list-disc marker:text-[#019444] ">
                                    <li>
                                        <a href="#" className="hover:text-[#019444] ">
                                            Contact Us
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className="hover:text-[#019444] ">
                                            All NFTs
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className="hover:text-[#019444] ">
                                            FAQ
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div className='ml-3 lg:ml-0'>
                                <h3 className={`text-lg font-bold ${theme === 'dark' ? 'text-white' : 'text-black'}`}>Contact Us</h3>
                                <p className="text-sm mt-4">Subscribe to Newsletter</p>
                                <div className="mt-2 ">
                                    <input
                                        type="email"
                                        placeholder="Enter your email"
                                        className={`w-[100%] outline-none border border-gray-300 rounded-lg px-3 py-2 text-sm focus:ring-green-600 focus:border-green-600 ${theme === 'dark' ? 'bg-black text-white' : 'bg-white text-black'}`}
                                    />
                                    <button className="bg-[#019444]  mt-2 text-white px-4 py-1 rounded-md active:translate-y-1 duration-300 text-sm">
                                        Subscribe
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`mt-8 font-semibold border-t border-gray-200 pt-6 flex flex-col md:flex-row text-center lg:text-end  justify-between text-sm ${theme === 'dark' ? 'border-t-white/20' : 'text-black'}`}>
                        <p className={`${theme === 'dark' ? 'text-white' : 'text-black'}`}>Copyright © 2024 OzoneX. All rights reserved.</p>
                        <div className="flex flex-col md:flex-row  lg:space-x-4">
                            <a href="#" className="text-[#019444] ">
                                Terms and Conditions
                            </a>
                            <a href="#" className="text-[#019444] ">
                                Privacy Policy
                            </a>
                            <a href="#" className="text-[#019444] ">
                                NFT Agreement
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}
