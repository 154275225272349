import React, { useState } from "react";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    password: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (errors[name]) {
      setErrors({ ...errors, [name]: "" });
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    }
    if (!formData.password) {
      newErrors.password = "Password is required";
    } else if (formData.password.length < 6) {
      newErrors.password = "Password must be at least 6 characters long";
    }
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      console.log("submitted");
    } else {
      setErrors(formErrors);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <div className="w-full h-screen bg-white bg-cover font-manrope">
      <div>
        <img src="../assets/bg/bglight.svg" className="w-full h-screen " />
        <div className="w-full fixed inset-0 flex flex-col justify-center items-center ">
          <div>
            <img src="../assets/Logo/Ozonelight.svg" className="w-36" />
          </div>
          <div className="mt-5">
            <p className=" font-medium text-3xl ">Login</p>
          </div>

          <form
            onSubmit={handleSubmit}
            className="mt-5 w-[95%] mx-auto max-w-sm bg-[#F8F8F8] rounded-lg border border-[#D9D8D8] sm:p-10 p-5 "
          >
            <div className="mb-4">
              <label className="block text-sm font-medium text-black">
                User Name*
              </label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="w-full px-4 py-1.5 border rounded-lg focus:outline-none "
              />
              {errors.name && (
                <span className="w-full text-red-500 text-sm">
                  {errors.name}
                </span>
              )}
            </div>

            <div className="mb-4 relative">
              <label className="block text-sm font-medium text-black">
                Password*
              </label>
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none "
              />
              <span
                onClick={togglePasswordVisibility}
                className="absolute right-3 top-8 cursor-pointer text-gray-500 hover:text-gray-700"
              >
                {showPassword ? (
                  <i class="fa-solid fa-eye fa-sm"></i>
                ) : (
                  <i class="fa-solid fa-eye-slash fa-sm"></i>
                )}
              </span>
              {errors.password && (
                <span className="text-red-500 text-sm">{errors.password}</span>
              )}
            </div>

            <div className="w-full flex justify-end mt-5">
              <button
                type="submit"
                className="bg-[#019444] font-medium text-sm text-black py-1.5 px-5 rounded-md hover:bg-opacity-85"
              >
                Next
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
