import React, { useState } from 'react'
import Header from '../Layout/Header'
import Sidebar from '../Layout/Sidebar';
import Dashboard from './Dashboard';

export default function Admindashboard() {
    const [theme, setTheme] = useState('light');

    const toggleTheme = () => {
        setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
    };

    
    return (
        <div>
            <Header theme={theme} toggleTheme={toggleTheme} />
            <Sidebar theme={theme} toggleTheme={toggleTheme} />
            <div className={`bg-contain bg-center lg:w-[80%] lg:absolute right-0 top-[10vh] lg:h-[90vh] overflow-hidden overflow-y-auto ${theme === 'dark' ? 'bg-black text-white' : 'bg-white text-black'}`}
                style={{
                    backgroundImage: `url("/assets/bg/${theme === 'dark' ? 'bgdark.svg' : 'bglight.svg'}")`,
                }}>
                <Dashboard theme={theme} toggleTheme={toggleTheme} />
            </div>
        </div>
    )
}
