import React, { useState } from "react";
import Header from "../Layout/Header";

const Assetverify = () => {
  const [theme, setTheme] = useState("light");
  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  return (
    <div
      className={`w-full bg-cover h-screen overflow-hidden overflow-y-auto font-manrope ${
        theme === "dark" ? "bg-black text-white" : "bg-white text-black"
      }`}
      style={{
        backgroundImage: `url("../assets/bg/${
          theme === "dark" ? "bgdark.svg" : "bglight.svg"
        }")`,
      }}
    >
      <div>
        <Header theme={theme} toggleTheme={toggleTheme} />
        <div className="mt-10">
          <p
            className={`  ${
              theme === "dark" ? "text-white " : " text-[#151515]"
            }  text-center md:text-2xl text-lg font-semibold `}
          >
            Asset Verification
          </p>
          <div className="w-[95%] max-w-xl mx-auto flex justify-center gap-10 mt-10 ">
            <div
              className={`w-full border rounded-lg py-5 px-10 h-full ${
                theme === "dark"
                  ? "bg-[#151515] border-[#2B2B2B] text-white"
                  : "bg-[#F8F8F8] border-[#D9D8D8] text-black"
              }`}
            >
              <div className=" pb-6 border-b border-gray-300/25 ">
                <div>
                  <p
                    className={`font-semibold ${
                      theme === "dark" ? "text-white" : "text-black"
                    } `}
                  >
                    Google Authentication
                  </p>
                  <p
                    className={`  leading-6 text-sm mt-1 ${
                      theme === "dark" ? "text-[#AAAAAA]" : " text-[#413D3D]"
                    } `}
                  >
                    2FA Code will be generated by your Google Authenticator app
                    available for both iOS and Android OS.{" "}
                  </p>
                </div>
                <div className="mt-5">
                  <button className="text-xs font-semibold bg-[#019444] text-black w-24 py-1.5 rounded-md">
                    Enable
                  </button>
                </div>
              </div>
              <div className=" py-5 ">
                <div>
                  <p
                    className={`font-semibold ${
                      theme === "dark" ? "text-white" : "text-black"
                    } `}
                  >
                    Email Authentication
                  </p>
                  <p
                    className={`  leading-6 text-sm mt-1 ${
                      theme === "dark" ? "text-[#AAAAAA]" : " text-[#413D3D]"
                    } `}
                  >
                    Send 2FA code via Email
                  </p>
                </div>
                <div className="mt-5">
                  <button className="text-xs font-semibold bg-[#019444] text-black w-24 py-1.5 rounded-md">
                    Enable
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Assetverify;
