import { faBell, faMoon, faSun } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import '../Style/Style.css'

export default function Header({ theme, toggleTheme }) {
    return (
        <div className={`h-[10vh] flex justify-center items-center ${theme === 'light' ? 'bg-[#D9D9D9] text-black' : 'bg-[#151515] text-white'}`}>
            <nav className='flex justify-between items-center px-5 w-[100%]'>
                <div>
                    <img src={`/assets/Logo/${theme === 'light' ? 'Ozonelight.svg' : 'Ozonedark.svg'}`}></img>
                </div>
                <div className='flex justify-end gap-3 lg:gap-7 items-center lg:text-xl '>
                    <FontAwesomeIcon icon={faBell} className='shake cursor-pointer' />
                    <img src='/assets/Others/Avatar.svg'></img>
                    <FontAwesomeIcon onClick={toggleTheme} icon={theme === 'light' ? faSun : faMoon} className='cursor-pointer' />
                </div>
            </nav>
        </div>
    )
}
