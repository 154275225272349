import React, { useState } from 'react';
import Header from '../Layout/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

export default function Scanqr() {
    const [theme, setTheme] = useState('light');

    const toggleTheme = () => {
        setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
    };

    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        const secretCode = document.getElementById('secret-code');
        if (secretCode) {
            navigator.clipboard.writeText(secretCode.value).then(() => {
                setCopied(true);
                setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
            });
        }
    };
    return (
        <div className={`bg-contain min-h-screen bg-center ${theme === 'dark' ? 'bg-black text-white' : 'bg-white text-black'}`}
            style={{
                backgroundImage: `url("/assets/bg/${theme === 'dark' ? 'bgdark.svg' : 'bglight.svg'}")`,
            }}>
            <Header theme={theme} toggleTheme={toggleTheme} />
            <div className='w-[90%] lg:w-[60%] mx-auto'>
                <h2 class="text-2xl font-semibold text-center  mb-6">
                    Scan QR code on mobile
                </h2>
                <p class="text-sm text-center mb-4">
                    Install Google Authenticator app on your mobile and scan the QR code. If you are unable to scan the QR code, please enter this code manually into the app.
                </p>
                <div class={`px-10 py-7 rounded-2xl border mt-10 flex flex-col lg:flex-row justify-between ${theme === 'dark' ? 'bg-[#151515] border-[#2B2B2B]' : 'border-[#D9D8D8] bg-[#F8F8F8] '}`}>
                    <div class="w-[60%] grid content-center">
                        <label class="font-semibold mb-2">
                            Google 2FA Secret Code
                        </label>
                        <div className="relative">
                            <input
                                id="secret-code"
                                type="text"
                                value="MNHB252UGAWDURBGMF6XJN3HNY7VOKCF"
                                readOnly
                                className={`w-full px-4 py-2 border rounded-md outline-none ${theme === 'dark' ? 'bg-[#58595B] border-[#58595B]' : 'bg-white'
                                    }`}
                            />
                            <FontAwesomeIcon
                                icon={faCopy}
                                className="absolute right-3 top-[50%] -translate-y-[50%] cursor-pointer "
                                onClick={handleCopy}
                            />
                            {copied && (
                                <span data-aos='fade-in' className="absolute right-3 top-[-20px] text-xs text-green-500">
                                    Copied!
                                </span>
                            )}
                        </div>
                        <label class={`font-medium mb-2 mt-5 ${theme === 'dark' ? 'text-gray-200' : ' text-gray-700'}`}>
                            Google Authentication Code
                        </label>
                        <input
                            id="auth-code"
                            type="text"
                            placeholder="Enter authentication code"
                            class={`w-full px-4 py-2 border rounded-md outline-none  ${theme === 'dark' ? 'bg-[#58595B] border-[#58595B]' : ' bg-white'}`}
                        />

                    </div>
                    <div className='w-[20%]'>
                        <div class="flex justify-center my-6">
                            <img
                                src={`assets/Others/${theme === 'dark' ? 'qrlight.png' : 'qr.png'}`}
                                alt="QR Code"
                            />
                        </div>
                        <button
                            class="w-full mt-6 bg-[#019444] font-medium py-2 rounded-md "
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
