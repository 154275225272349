import React, { useState } from "react";
import Header from "../Layout/Header";

const Profilesetup = () => {
  const [theme, setTheme] = useState("light");
  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  return (
    <div
      className={`w-full bg-cover h-screen overflow-hidden overflow-y-auto font-manrope ${
        theme === "dark" ? "bg-black text-white" : "bg-white text-black"
      }`}
      style={{
        backgroundImage: `url("../assets/bg/${
          theme === "dark" ? "bgdark.svg" : "bglight.svg"
        }")`,
      }}
    >
      <div>
        <Header theme={theme} toggleTheme={toggleTheme} />
        <div>
          <p
            className={`  ${
              theme === "dark" ? "text-white " : " text-[#151515]"
            }  text-center md:text-2xl text-lg font-semibold `}
          >
            Profile Setup
          </p>
          <div className="w-[95%] lg:w-[85%] xl:w-[80%] mx-auto flex md:flex-row flex-col justify-center md:items-start items-center gap-10 mt-10 md:mb-0 mb-10 ">
            <div
              className={`  sm:w-[80%] md:w-1/2 border rounded-lg py-5 px-10 h-full ${
                theme === "dark"
                  ? "bg-[#151515] border-[#2B2B2B] text-white"
                  : "bg-[#F8F8F8] border-[#D9D8D8] text-black"
              }`}
            >
              <div className="flex justify-between items-center pb-3 border-b border-gray-300/25 ">
                <div>
                  <p
                    className={`font-semibold ${
                      theme === "dark" ? "text-white" : "text-black"
                    } `}
                  >
                    Username
                  </p>
                  <p
                    className={` text-xs mt-1 ${
                      theme === "dark" ? "text-[#AAAAAA]" : " text-[#413D3D]"
                    } `}
                  >
                    Set a customized username for your profile.
                  </p>
                </div>
                <div>
                  <button className="text-xs font-semibold bg-[#019444] w-24 p-1.5 rounded-md">
                    Change/Edit
                  </button>
                </div>
              </div>
              <div className="flex justify-between items-center py-4 border-b border-gray-300/25 ">
                <div>
                  <p
                    className={`font-semibold ${
                      theme === "dark" ? "text-white" : "text-black"
                    } `}
                  >
                    Avatar
                  </p>
                  <p
                    className={` text-xs mt-1 ${
                      theme === "dark" ? "text-[#AAAAAA]" : " text-[#413D3D]"
                    } `}
                  >
                    Select an avatar to personalize your account..
                  </p>
                </div>
                <div>                    
                  <button className="text-xs font-semibold bg-[#019444] w-24 p-1.5 rounded-md">
                    Change/Edit
                  </button>
                </div>
              </div>
              <div className="flex justify-between items-center py-4 border-b border-gray-300/25 ">
                <div>
                  <p
                    className={`font-semibold ${
                      theme === "dark" ? "text-white" : "text-black"
                    } `}
                  >
                    Email
                  </p>
                  <p
                    className={` text-xs mt-1 ${
                      theme === "dark" ? "text-[#AAAAAA]" : " text-[#413D3D]"
                    } `}
                  >
                    Edit your email address for your profile.
                  </p>
                </div>
                <div>
                  <button className="text-xs font-semibold bg-[#019444] w-24 p-1.5 rounded-md">
                    Change/Edit
                  </button>
                </div>
              </div>
              <div className="flex justify-between items-center py-4 border-b border-gray-300/25 ">
                <div>
                  <p
                    className={`font-semibold ${
                      theme === "dark" ? "text-white" : "text-black"
                    } `}
                  >
                    Change password
                  </p>
                  <p
                    className={` text-xs mt-1 ${
                      theme === "dark" ? "text-[#AAAAAA]" : " text-[#413D3D]"
                    } `}
                  >
                    Edit your Password for your profile.
                  </p>
                </div>
                <div>
                  <button className="text-xs font-semibold bg-[#019444] w-24 p-1.5 rounded-md">
                   Update
                  </button>
                </div>
              </div>
              <div className="flex justify-between items-center py-4 border-b border-gray-300/25 ">
                <div>
                  <p
                    className={`font-semibold ${
                      theme === "dark" ? "text-white" : "text-black"
                    } `}
                  >
                    Delete User
                  </p>
                  <p
                    className={` text-xs mt-1 ${
                      theme === "dark" ? "text-[#AAAAAA]" : " text-[#413D3D]"
                    } `}
                  >
                    Edit your email address for your profile.
                  </p>
                </div>
                <div>
                  <button className="text-xs font-semibold bg-[#019444] w-24 p-1.5 rounded-md">
                 Update
                  </button>
                </div>
              </div>
            </div>

            <div
              className={`  sm:w-[80%] md:w-1/2 border rounded-lg py-5 px-10 h-full ${
                theme === "dark"
                  ? "bg-[#151515] border-[#2B2B2B] text-white"
                  : "bg-[#F8F8F8] border-[#D9D8D8] text-black"
              }`}
            >
              <div className="flex justify-between items-center pb-4 border-b border-gray-300/25 ">
                <div>
                  <p
                    className={`font-semibold ${
                      theme === "dark" ? "text-white" : "text-black"
                    } `}
                  >
                    My Asset
                  </p>
                  <p
                    className={` text-xs mt-1 ${
                      theme === "dark" ? "text-[#AAAAAA]" : " text-[#413D3D]"
                    } `}
                  >
                    Edit your Password for your profile.
                  </p>
                </div>
                <div>
                  <button className="text-xs font-semibold bg-[#019444] w-24 p-1.5 rounded-md">
                    View
                  </button>
                </div>
              </div>
              <div className="flex justify-between items-center py-4 border-b border-gray-300/25 ">
                <div>
                  <p
                    className={`font-semibold ${
                      theme === "dark" ? "text-white" : "text-black"
                    } `}
                  >
                    My Bank Details
                  </p>
                  <p
                    className={` text-xs mt-1 ${
                      theme === "dark" ? "text-[#AAAAAA]" : " text-[#413D3D]"
                    } `}
                  >
                    Edit your email address for your profile.
                  </p>
                </div>
                <div>
                  <button className="text-xs font-semibold bg-[#019444] w-24 p-1.5 rounded-md">
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profilesetup;
