import React, { useState } from "react";
import Header from "../Layout/Header";

const Kycverify = () => {
  const [theme, setTheme] = useState("light");
  const [currentStep, setCurrentStep] = useState(1);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    dob: "",
    city: "",
    country: "",
    phnnumber: "",
    idnumber: "",
    expiry: "",
    address: "",
    picture1: "",
    picture2: "",
    picture3: "",
  });

  const [errors, setErrors] = useState({
    firstname: "",
    lastname: "",
    dob: "",
    city: "",
    country: "",
    phnnumber: "",
    idnumber: "",
    expiry: "",
    address: "",
    picture1: "",
    picture2: "",
    picture3: "",
  });

  const handleFileChange = (e, pictureKey) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prevState) => ({
          ...prevState,
          [pictureKey]: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (errors[name]) {
      setErrors({ ...errors, [name]: "" });
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.firstname.trim()) {
      newErrors.firstname = "First name is required";
    }
    if (!formData.lastname.trim()) {
      newErrors.lastname = "Last name is required";
    }
    if (!formData.dob.trim()) {
      newErrors.dob = "Date of birth is required";
    }
    if (!formData.city.trim()) {
      newErrors.city = "City is required";
    }
    if (!formData.country.trim()) {
      newErrors.country = "Country is required";
    }
    if (!formData.phnnumber.trim()) {
      newErrors.phnnumber = "Phone number is required";
    }

    return newErrors;
  };

  const validateForm2 = () => {
    const newErrors = {};
    if (!formData.idnumber.trim()) {
      newErrors.idnumber = "Id number is required";
    }
    if (!formData.expiry.trim()) {
      newErrors.expiry = "Expiry is required";
    }
    if (!formData.address.trim()) {
      newErrors.address = "Address is required";
    }

    return newErrors;
  };

  const handleNext = (e) => {
    e.preventDefault();

    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      setCurrentStep(2);
      console.log("submitted");
    } else {
      setErrors(formErrors);
    }
  };

  const handlestep2 = (a) => {
    a.preventDefault();

    const formErrors = validateForm2();
    if (Object.keys(formErrors).length === 0) {
      setCurrentStep(3);
      console.log("submitted");
    } else {
      setErrors(formErrors);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formErrors = validateForm3();
    if (Object.keys(formErrors).length === 0) {
      console.log("Form submitted successfully");
    } else {
      setErrors(formErrors);
    }
  };

  const validateForm3 = () => {
    const newErrors = {};
    if (!formData.picture1) newErrors.picture1 = "Picture 1 is required";
    if (!formData.picture2) newErrors.picture2 = "Picture 2 is required";
    if (!formData.picture3) newErrors.picture3 = "Picture 3 is required";
    return newErrors;
  };

  return (
    <div
      className={`bg-cover h-screen pb-10 overflow-hidden overflow-y-auto font-manrope ${
        theme === "dark" ? "bg-black text-white" : "bg-white text-black"
      }`}
      style={{
        backgroundImage: `url("../assets/bg/${
          theme === "dark" ? "bgdark.svg" : "bglight.svg"
        }")`,
      }}
    >
      <Header theme={theme} toggleTheme={toggleTheme} />
      <div className="w-full flex flex-col justify-center items-center pb-10 sm:pb-0">
        <div className=" w-[95%] mx-auto max-w-md">
          <p className=" font-semibold text-2xl text-center ">
            Kyc Verfication
          </p>

          {currentStep === 1 && (
            <div>
              <p className="text-[#019444] font-medium px-3 mt-3">
                Account Information
              </p>

              <form
                onSubmit={handleNext}
                className={`${
                  theme === "dark"
                    ? "bg-[#151515] text-white border-[#2B2B2B]"
                    : "bg-[#F8F8F8] border-[#D9D8D8] text-black"
                } mt-1 text-black  rounded-lg border sm:px-10 py-6 px-5 `}
              >
                <div className="mb-3">
                  <label className="block text-sm font-medium ">
                    First Name*
                  </label>
                  <input
                    type="text"
                    name="firstname"
                    value={formData.firstname}
                    onChange={handleInputChange}
                    className="w-full px-4 py-1.5 mt-1 text-black text-sm shadow-sm rounded-md focus:outline-none"
                  />
                  {errors.firstname && (
                    <span className="w-full text-red-500 text-xs">
                      {errors.firstname}
                    </span>
                  )}
                </div>

                <div className="mb-4">
                  <label className="block text-sm font-medium ">
                    Last Name *
                  </label>
                  <input
                    type="text"
                    name="lastname"
                    value={formData.lastname}
                    onChange={handleInputChange}
                    className="w-full px-4 py-1.5 mt-1 text-black text-sm shadow-sm rounded-md focus:outline-none "
                  />
                  {errors.lastname && (
                    <span className="w-full text-red-500 text-xs">
                      {errors.lastname}
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="block text-sm font-medium ">
                    Date of Birth *
                  </label>
                  <input
                    type="text"
                    name="dob"
                    value={formData.dob}
                    onChange={handleInputChange}
                    className="w-full px-4 py-1.5 mt-1 text-black text-sm shadow-sm rounded-md focus:outline-none "
                  />
                  {errors.dob && (
                    <span className="w-full text-red-500 text-xs">
                      {errors.dob}
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="block text-sm font-medium ">City *</label>
                  <input
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={handleInputChange}
                    className="w-full px-4 py-1.5 mt-1 text-black text-sm shadow-sm rounded-md focus:outline-none "
                  />
                  {errors.city && (
                    <span className="w-full text-red-500 text-xs">
                      {errors.city}
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="block text-sm font-medium ">
                    Country *
                  </label>
                  <input
                    type="text"
                    name="country"
                    value={formData.country}
                    onChange={handleInputChange}
                    className="w-full px-4 py-1.5 mt-1 text-black text-sm shadow-sm rounded-md focus:outline-none "
                  />
                  {errors.country && (
                    <span className="w-full text-red-500 text-xs">
                      {errors.country}
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="block text-sm font-medium ">
                    Phone Number*
                  </label>
                  <input
                    type="text"
                    name="phnnumber"
                    value={formData.phnnumber}
                    onChange={handleInputChange}
                    className="w-full px-4 py-1.5 mt-1 text-black text-sm shadow-sm rounded-md focus:outline-none "
                  />
                  {errors.phnnumber && (
                    <span className="w-full text-red-500 text-xs">
                      {errors.phnnumber}
                    </span>
                  )}
                </div>

                <div className="w-full flex justify-end mt-5">
                  <button
                    type="submit"
                    className="bg-[#019444] font-medium text-sm text-black py-1.5 px-5 rounded-md hover:bg-opacity-85"
                  >
                    Next
                  </button>
                </div>
              </form>
            </div>
          )}

          {currentStep === 2 && (
            <div>
              <p className="text-[#019444] font-medium px-3 mt-3">
                Id Credentials
              </p>
              <form
                onSubmit={handlestep2}
                className={`${
                  theme === "dark"
                    ? "bg-[#151515] text-white border-[#2B2B2B]"
                    : "bg-[#F8F8F8] border-[#D9D8D8] text-black"
                } mt-1 text-black  rounded-lg border sm:px-10 py-6 px-5 `}
              >
                <div className="mb-3">
                  <label className="block text-sm font-medium ">
                    Id Number *
                  </label>
                  <input
                    type="text"
                    name="idnumber"
                    value={formData.idnumber}
                    onChange={handleInputChange}
                    className="w-full px-4 py-1.5 mt-1 text-black text-sm shadow-sm rounded-md focus:outline-none"
                  />
                  {errors.idnumber && (
                    <span className="w-full text-red-500 text-xs">
                      {errors.idnumber}
                    </span>
                  )}
                </div>

                <div className="mb-3">
                  <label className="block text-sm font-medium ">
                    Expire Date
                  </label>
                  <input
                    type="text"
                    name="expiry"
                    value={formData.expiry}
                    onChange={handleInputChange}
                    className="w-full px-4 py-1.5 mt-1 text-black text-sm shadow-sm rounded-md focus:outline-none"
                  />
                  {errors.expiry && (
                    <span className="w-full text-red-500 text-xs">
                      {errors.expiry}
                    </span>
                  )}
                </div>

                <div className="mb-3">
                  <label className="block text-sm font-medium ">
                    Address *
                  </label>
                  <input
                    type="text"
                    name="address"
                    value={formData.address}
                    onChange={handleInputChange}
                    className="w-full px-4 py-1.5 mt-1 text-black text-sm shadow-sm rounded-md focus:outline-none"
                  />
                  {errors.address && (
                    <span className="w-full text-red-500 text-xs">
                      {errors.address}
                    </span>
                  )}
                </div>

                <div className="w-full flex items-center gap-7 justify-end mt-5">
                  <button
                    onClick={() => setCurrentStep(1)}
                    className={` text-sm font-medium${
                      theme === "dark" ? "text-white" : "text-black"
                    } `}
                  >
                    Back
                  </button>
                  <button
                    type="submit"
                    className="bg-[#019444] font-medium text-sm text-black py-1.5 px-5 rounded-md hover:bg-opacity-85"
                  >
                    Next
                  </button>
                </div>
              </form>
            </div>
          )}

          {currentStep === 3 && (
            <div>
              <p className="text-[#019444] font-medium px-3 mt-3">
                Id Document
              </p>
              <form
                onSubmit={handleSubmit}
                className={`${
                  theme === "dark"
                    ? "bg-[#151515] text-white border-[#2B2B2B]"
                    : "bg-[#F8F8F8] border-[#D9D8D8] text-black"
                } mt-1 text-black  rounded-lg border sm:px-10 py-6 px-5 `}
              >
                <div className="mb-3">
                  <label className="block text-sm font-medium  text-center">
                    Document Front Image*
                  </label>
                  <div className="relative flex justify-center items-center">
                    <input
                      type="file"
                      name="picture1"
                      accept="image/*"
                      onChange={(e) => handleFileChange(e, "picture1")}
                      id="file1"
                      className="hidden"
                    />
                    <label
                      htmlFor="file1"
                      className="w-7/12 cursor-pointer flex items-center justify-center rounded-md"
                    >
                      {formData.picture1 ? (
                        <img
                          src={
                            formData.picture1
                              ? formData.picture1
                              : "../assets/Others/defaultimg.png"
                          }
                          alt="Preview 1"
                          className="w-full h-full object-cover rounded-md"
                        />
                      ) : (
                        <img
                          src="../assets/Others/defaultimg.png"
                          alt="Preview 1"
                          className="w-full h-full object-cover rounded-md"
                        />
                      )}
                    </label>
                  </div>
                  {errors.picture1 && (
                    <span className="w-full text-red-500 text-center text-xs">
                      {errors.picture1}
                    </span>
                  )}
                </div>

                <div className="mb-3">
                  <label className="block text-sm font-medium  text-center">
                    Document Back Image*
                  </label>
                  <div className="relative flex justify-center items-center">
                    <input
                      type="file"
                      name="picture2"
                      accept="image/*"
                      onChange={(e) => handleFileChange(e, "picture2")}
                      id="file2"
                      className="hidden"
                    />
                    <label
                      htmlFor="file2"
                      className="w-7/12 cursor-pointer flex items-center justify-center rounded-md"
                    >
                      {formData.picture2 ? (
                        <img
                          src={formData.picture2}
                          alt="Preview 2"
                          className="w-full h-full object-cover rounded-md"
                        />
                      ) : (
                        <img
                          src="../assets/Others/defaultimg.png"
                          alt="Preview 1"
                          className="w-full h-full object-cover rounded-md"
                        />
                      )}
                    </label>
                  </div>
                  {errors.picture2 && (
                    <span className="w-full text-red-500 text-center text-xs">
                      {errors.picture2}
                    </span>
                  )}
                </div>

                <div className="mb-3">
                  <label className="block text-sm font-medium  text-center">
                    selfie image*
                  </label>
                  <div className="relative flex justify-center items-center">
                    <input
                      type="file"
                      name="picture3"
                      accept="image/*"
                      onChange={(e) => handleFileChange(e, "picture3")}
                      id="file3"
                      className="hidden"
                    />
                    <label
                      htmlFor="file3"
                      className="w-7/12 cursor-pointer flex items-center justify-center rounded-md"
                    >
                      {formData.picture3 ? (
                        <img
                          src={formData.picture3}
                          alt="Preview 3"
                          className="w-full h-full object-cover rounded-md"
                        />
                      ) : (
                        <img
                          src="../assets/Others/defaultselfie.png"
                          alt="Preview 1"
                          className="w-full h-full object-cover rounded-md"
                        />
                      )}
                    </label>
                  </div>
                  {errors.picture3 && (
                    <span className="w-full text-red-500 text-center text-xs">
                      {errors.picture3}
                    </span>
                  )}
                </div>

                <div className="w-full flex justify-end mt-5">
                  <button
                    type="submit"
                    className="bg-[#019444] font-medium text-sm text-black py-1.5 px-5 rounded-md hover:bg-opacity-85"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Kycverify;
