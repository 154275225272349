import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Login from '../Components/Auth/Login';
import Kycverify from '../Components/Auth/Kycverify';
import Profilesetup from '../Components/Auth/Profilesetup';

import Assets from '../Components/Pages/Assets';
import Assetverify from '../Components/Pages/Assetverify';
import Marketplace from '../Components/Pages/Marketplace';
import CreateNft from '../Components/Pages/CreateNft';
import NFTname from '../Components/Pages/NFTname';
import Scanqr from '../Components/Pages/Scanqr';
import EmailOTP from '../Components/Pages/EmailOTP';
import Walletmetamask from '../Components/Pages/Walletmetamask';
import SingleNft from '../Components/Pages/SingleNFT';
import FractionalNft from '../Components/Pages/FractionalNft';


import Admindashboard from '../Admin/Pages/Admindashboard';


const Routers = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/*Auth*/}
        <Route path="/Login" element={<Login />} />
        <Route path="/Kycverify" element={<Kycverify />} />
        <Route path="/Profilesetup" element={<Profilesetup />} />

        {/*Pages*/}
        <Route path="/" element={<Assets />} />
        <Route path="/Assetsverify" element={<Assetverify />} />
        <Route path="/marketplace" element={<Marketplace />} />
        <Route path="/createnft" element={<CreateNft />} />
        <Route path="/nftname" element={<NFTname />} />
        <Route path="/scanqr" element={<Scanqr />} />
        <Route path="/emailotp" element={<EmailOTP />} />
        <Route path="/Walletmetamask" element={<Walletmetamask />} />
        <Route path="/Singlenft" element={<SingleNft />} />
        <Route path="/fractionalnft" element={<FractionalNft />} />

        {/*Admin*/}
        <Route path="/admin/dashboard" element={<Admindashboard/>} />

      </Routes>
    </BrowserRouter>
  );
};

export default Routers;
