import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Routers from './Routes/Routers';
import { createAppKit } from '@reown/appkit/react';
import { Ethers5Adapter } from '@reown/appkit-adapter-ethers5';
import { mainnet, arbitrum } from '@reown/appkit/networks';

const root = ReactDOM.createRoot(document.getElementById('root'));

const projectId = '55ceec1669dd6f24f07d1e0d1bed7a23';

// 2. Create a metadata object - optional
const metadata = {
  name: 'My Website',
  description: 'My Website description',
  url: 'https://mywebsite.com', // origin must match your domain & subdomain
  icons: ['https://avatars.mywebsite.com/']
};

let color = 'light';

// 3. Create the AppKit instance with custom theme variables
createAppKit({
  adapters: [new Ethers5Adapter()],
  metadata: metadata,
  networks: [mainnet, arbitrum],
  projectId,
  themeMode: color,
  features: {
    analytics: true // Optional - defaults to your Cloud configuration
  },
  themeVariables: {
    '--w3m-accent': '#019444', // Primary color of the button
    '--w3m-accent-hover': '#F98736', // Button hover color
    '--w3m-accent-text-color': '#FFFFFF', // Text color on the button
    '--w3m-border-radius-master': '1px', // Rounded corners for the button
  }
});

root.render(
  <React.StrictMode>
    <Routers />  
  </React.StrictMode>
);

reportWebVitals();
