import { faSun, faMoon, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'

export default function Header({ theme, toggleTheme }) {
  const [isDropdown, setDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      (isDropdown && dropdownRef.current && !dropdownRef.current.contains(event.target) && buttonRef.current && !buttonRef.current.contains(event.target))
    ) {
      setDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isDropdown]);

  const handleDropdown = () => {
    setDropdown(!isDropdown)
  }
  return (
    <div>
      <div className='flex justify-between items-center w-[90%] mx-auto py-5'>
        <div>
          <img src={`/assets/Logo/${theme === 'light' ? 'Ozonelight.svg' : 'Ozonedark.svg'}`}></img>
        </div>
        <div className='hidden lg:block'>
          <div className='flex gap-10 items-center font-medium justify-end'>
            <a href='#'>Buy Now</a>
            <a href='/marketplace'>Market place</a>
            <a href='/Profilesetup'>Profile</a>
            <a href='#'>Auction</a>
            <appkit-button />
            <button><FontAwesomeIcon icon={faUser} className='text-gray-400' /></button>
            <button onClick={toggleTheme}>
              <FontAwesomeIcon
                icon={theme === 'light' ? faSun : faMoon}
                className='text-gray-400'
              />
            </button>
          </div>
        </div>
        <div className='block lg:hidden'>
          <div className='flex items-center justify-end gap-3'>
            <FontAwesomeIcon icon={faUser} className='text-gray-400 text-lg' />
            <FontAwesomeIcon onClick={toggleTheme} icon={theme === 'light' ? faSun : faMoon} className='text-gray-400 text-lg' />
            <button className="" onClick={handleDropdown} ref={buttonRef}>
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      {isDropdown && (
        <div className="absolute right-3 z-30" data-aos="flip-right" data-aos-duration='1000' ref={dropdownRef}>
          <nav className={`flex flex-col gap-1.5  text-center  border shadow-md rounded-xl p-3 font-semibold ${theme === 'light' ? 'bg-white' : 'bg-[#151515] border-[#2B2B2B]'}`}>
            <a href='#'>Buy Now</a>
            <a href='#'>Market place</a>
            <a href='#'>Profile</a>
            <a href='#'>Auction</a>
            <appkit-button />
          </nav>
        </div>
      )}
    </div>
  )
}
