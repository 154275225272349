import React from 'react'
import { useState } from 'react';
import Header from '../Layout/Header'
import Select from 'react-select';
export default function CreateNft() {
    const [theme, setTheme] = useState("light");

    const toggleTheme = () => {
      setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
    };
    const Sorted = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ]

    const CustomStyle = {
        control: (provided, state) => ({
            ...provided,
            border: '0px',
            backgroundColor: 'transparent',
            boxShadow: '0px',
            color: 'white',
            paddingLeft: '8px',
            outline: 'none',
        }),
        placeholder: (provided, state) => ({
            ...provided,
         
            fontSize: '14px',
        }),
        option: (provided, state) => ({
            ...provided,
            textAlign: 'center',
            backgroundColor: state.isFocused ? '#d3d3d3' : 'white',
            color: '#000', // Color of the option text
            ':active': {
                backgroundColor: '#d3d3d3', // Light gray when clicked
            },
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: '#000', // Color of the selected option text
        }),
        input: (provided, state) => ({
            ...provided,
            color: '#fff', // Color of the typed input text
        }),
        menu: (provided, state) => ({
            ...provided,
            width:'10rem',
            background: '#fff', // Color of the typed input text
        }),
    };
  return (
    <div  className={`bg-contain bg-center font-Manrope ${
        theme === "dark" ? "bg-black text-white " : "bg-white text-black"
      }`}
      style={{
        backgroundImage: `url("/assets/Bg/${
          theme === "dark" ? "bgdark.png" : "bglight.png"
        }")`,
      }}>
      <Header theme={theme} toggleTheme={toggleTheme} />
      <div className='Sec-1 lg:px-20 lg:py-10 px-5'>
         <div>
           <p className='text-2xl text-center mt-10'>Create NFT</p>
         </div>
         <div className=''>
           <p className='text-xl font-bold'>Project Category</p>
         </div>
         <div className={`w-[100%] p-4 mt-4 rounded-md shadow-md flex flex-col gap-5 lg:gap-0 lg:flex-row justify-between items-center ${
            theme === "dark"
              ? "bg-[#151515] text-white border border-[#2b2b2b]"
              : "bg- text-black border"
          }`}>
             <div className='lg:w-[70%]'>
                <p className=''>Project Name *</p>
                <Select options={Sorted} styles={CustomStyle} placeholder='Real Estate,Mall,Resort,Agri Land,Gold,Aluminum,Silver'/>
             </div>
             <div className='lg:w-[30%] flex justify-end'>
             <button className=" text-black rounded-lg px-8 py-1 bg-[#019444]">Submit</button>

             </div> 
         </div>
         
      </div>
      <div className='Sec-2 lg:px-20 lg:py-10 px-5'>
     <div>
     <p className='text-xl font-bold mt-10'>Project Details</p>
     </div> 
      <div className={`w-[100%] p-4  rounded-md shadow-md  ${
         theme === "dark"
           ? "bg-[#151515] text-white border border-[#2b2b2b]"
           : "bg- text-black border"
       }`}>
           <div className='flex flex-col gap-10'>
            <div className='flex flex-col lg:flex-row justify-start gap-5'>
               <div className='lg:w-[25%] flex flex-col gap-1'>
                   <p className='text-sm'>Project Name *</p>
                   <input className={`w-[100%] outline-none px-3 py-1  rounded-md shadow-md  ${
                    theme === "dark"
                      ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                      : "bg-white text-black border"
                  }`}></input>
               </div>
               <div className='lg:w-[25%] flex flex-col gap-1'>
               <p className='text-sm'>Offering Date *</p>
               <input className={`w-[100%] outline-none px-3 py-1  rounded-md shadow-md  ${
                theme === "dark"
                  ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                  : "bg-white text-black border"
              }`}></input>
           </div>
           <div className='lg:w-[25%] flex flex-col gap-1'>
           <p className='text-sm'>Project Status *</p>
           <input className={`w-[100%] outline-none px-3 py-1  rounded-md shadow-md  ${
            theme === "dark"
              ? "bg-[#58595b] text-white border border-[#2b2b2b]"
              : "bg-white text-black border"
          }`}></input>
       </div>
       <div className='lg:w-[25%] flex flex-col gap-1'>
       <p className='text-sm'>Investment Type  *</p>
       <input className={`w-[100%] outline-none px-3 py-1  rounded-md shadow-md  ${
        theme === "dark"
          ? "bg-[#58595b] text-white border border-[#2b2b2b]"
          : "bg-white text-black border"
      }`}></input>
   </div>
            </div>
            <div className='flex flex-col  lg:flex-row justify-start gap-5'>
            <div className='lg:w-[25%] flex flex-col gap-1'>
                <p className='text-sm'>Total Invesment *</p>
                <input className={`w-[100%] outline-none px-3 py-1  rounded-md shadow-md  ${
                 theme === "dark"
                   ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                   : "bg-white text-black border"
               }`}></input>
            </div>
            <div className='lg:w-[25%] flex flex-col gap-1'>
            <p className='text-sm'>Project Type *</p>
            <input className={`w-[100%] outline-none px-3 py-1  rounded-md shadow-md  ${
             theme === "dark"
               ? "bg-[#58595b] text-white border border-[#2b2b2b]"
               : "bg-white text-black border"
           }`}></input>
        </div>
        <div className='lg:w-[25%] flex flex-col gap-1'>
        <p className='text-sm'>Investment Term *</p>
        <input className={`w-[100%] outline-none px-3 py-1  rounded-md shadow-md  ${
         theme === "dark"
           ? "bg-[#58595b] text-white border border-[#2b2b2b]"
           : "bg-white text-black border"
       }`}></input>
    </div>
    <div className='lg:w-[25%] flex flex-col gap-1'>
    <p className='text-sm'>Agent Commision  *</p>
    <input className={`w-[100%] outline-none px-3 py-1  rounded-md shadow-md  ${
     theme === "dark"
       ? "bg-[#58595b] text-white border border-[#2b2b2b]"
       : "bg-white text-black border"
   }`}></input>
</div>
         </div>
         <div className='flex flex-col lg:flex-row justify-start gap-5'>
         <div className='lg:w-[25%] flex flex-col gap-1'>
             <p className='text-sm'>Issuer *</p>
             <input className={`w-[100%] outline-none px-3 py-1  rounded-md shadow-md  ${
              theme === "dark"
                ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                : "bg-white text-black border"
            }`}></input>
         </div>
         <div className='lg:w-[25%] flex flex-col gap-1'>
         <p className='text-sm'>Fraction NFT Price in Ozonex *</p>
         <input className={`w-[100%] outline-none px-3 py-1  rounded-md shadow-md  ${
          theme === "dark"
            ? "bg-[#58595b] text-white border border-[#2b2b2b]"
            : "bg-white text-black border"
        }`}></input>
     </div>
     <div className='lg:w-[25%] flex flex-col gap-1'>
     <p className='text-sm'>Fraction NFT Price in USDT *</p>
     <input className={`w-[100%] outline-none px-3 py-1  rounded-md shadow-md  ${
      theme === "dark"
        ? "bg-[#58595b] text-white border border-[#2b2b2b]"
        : "bg-white text-black border"
    }`}></input>
 </div>
 <div className='lg:w-[25%] flex flex-col gap-1'>
 <p className='text-sm'>Total Fraction NFT *</p>
 <input className={`w-[100%] outline-none px-3 py-1  rounded-md shadow-md  ${
  theme === "dark"
    ? "bg-[#58595b] text-white border border-[#2b2b2b]"
    : "bg-white text-black border"
}`}></input>
</div>
      </div>
      <div className='flex flex-col lg:flex-row justify-start gap-5'>
      <div className='lg:w-[25%] flex flex-col gap-1'>
          <p className='text-sm'>Expected  Profit per Fracton  *</p>
          <input className={`w-[100%] outline-none px-3 py-1  rounded-md shadow-md  ${
           theme === "dark"
             ? "bg-[#58595b] text-white border border-[#2b2b2b]"
             : "bg-white text-black border"
         }`}></input>
      </div>
      <div className='lg:w-[25%] flex flex-col gap-1'>
      <p className='text-sm'>%Ozonex  Bid /  Offer Price *</p>
      <input className={`w-[100%] outline-none px-3 py-1  rounded-md shadow-md  ${
       theme === "dark"
         ? "bg-[#58595b] text-white border border-[#2b2b2b]"
         : "bg-white text-black border"
     }`}></input>
  </div>
  <div className='lg:w-[25%] flex flex-col gap-1'>
  <p className='text-sm'>Return Period *</p>
  <input className={`w-[100%] outline-none px-3 py-1  rounded-md shadow-md  ${
   theme === "dark"
     ? "bg-[#58595b] text-white border border-[#2b2b2b]"
     : "bg-white text-black border"
 }`}></input>
</div>
<div className='lg:w-[25%] flex flex-col gap-1'>
<p className='text-sm'>Project Profit *</p>
<input className={`w-[100%] outline-none px-3 py-1  rounded-md shadow-md  ${
theme === "dark"
 ? "bg-[#58595b] text-white border border-[#2b2b2b]"
 : "bg-white text-black border"
}`}></input>
</div>
   </div>
   <div className='flex flex-col lg:flex-row justify-start gap-5'>
   <div className='lg:w-[23%] flex flex-col gap-1'>
       <p className='text-sm'>Project Commisi on  *</p>
       <input className={`w-[100%] outline-none px-3 py-1  rounded-md shadow-md  ${
        theme === "dark"
          ? "bg-[#58595b] text-white border border-[#2b2b2b]"
          : "bg-white text-black border"
      }`}></input>
   </div>
   <div className='lg:w-[24%] flex flex-col gap-1'>
   <p className='text-sm'>Official Document *</p>
   <input className={`w-[100%] outline-none px-3 py-1  rounded-md shadow-md  ${
    theme === "dark"
      ? "bg-[#58595b] text-white border border-[#2b2b2b]"
      : "bg-white text-black border"
  }`}></input>
</div>

</div>
           </div>
         
         </div>
      </div>
      <div className='Sec-3 lg:px-20 lg:py-10 px-5'>
         <div className=''>
           <p className='text-xl font-bold'>Project Phases</p>
         </div>
        <div className={`w-[100%] p-4  rounded-md shadow-md  ${
            theme === "dark"
              ? "bg-[#151515] text-white border border-[#2b2b2b]"
              : "bg- text-black border"
          }`}>
             <div className='flex flex-col gap-10'>
               <div className='flex flex-col gap-5 lg:gap-0 lg:flex-row justify-between items-center'>
                 <div className='lg:w-[50%] flex justify-center gap-5'>
                 <div className='w-[50%] flex flex-col gap-1'>
                 <p className='text-sm'>Name  *</p>
                 <input className={`w-[100%] outline-none px-3 py-1  rounded-md shadow-md  ${
                  theme === "dark"
                    ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                    : "bg-white text-black border"
                }`}></input>
             </div>
             <div className='w-[50%] flex flex-col gap-1'>
             <p className='text-sm'>Opening Date *</p>
             <input className={`w-[100%] outline-none px-3 py-1  rounded-md shadow-md  ${
              theme === "dark"
                ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                : "bg-white text-black border"
            }`}></input>
          </div>
                 </div>
                 <div className='lg:w-[50%] flex justify-end'>
                 <button className=" text-black rounded-lg px-8 py-1 bg-[#019444]">Submit</button>

                 </div>
               </div>
               <div className='flex flex-col gap-5 lg:gap-0 lg:flex-row justify-between'>
               <div className='lg:w-[30%] flex flex-col gap-1'>
               <p className='text-sm'>Project Cover Image Page  *</p>
               <input type='file' className={`w-[100%] outline-none px-3 py-1  rounded-md shadow-md  ${
                theme === "dark"
                  ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                  : "bg-white text-black border"
              }`}></input>
           </div>
           <div className='lg:w-[30%] flex flex-col gap-1'>
           <p className='text-sm'>Project Video  *</p>
           <input placeholder='URl' className={`w-[100%] outline-none px-3 py-1  rounded-md shadow-md  ${
            theme === "dark"
              ? "bg-[#58595b] text-white border border-[#2b2b2b]"
              : "bg-white text-black border"
          }`}></input>
        </div>
        <div className='lg:w-[30%] flex flex-col gap-1'>
        <p className='text-sm'>Project Location  *</p>
        <input placeholder='Insert Google Map URL' className={`w-[100%] outline-none px-3 py-1  rounded-md shadow-md  ${
         theme === "dark"
           ? "bg-[#58595b] text-white border border-[#2b2b2b]"
           : "bg-white text-black border"
       }`}></input>
     </div>
               </div>
               <div className='flex flex-col gap-5 lg:gap-0 lg:flex-row justify-around items-center'>
                <div className='lg:w-[30%]'>
                   <img src='/assets/Others/Create-nft-building.svg'></img>
                </div>
                <div className='lg:w-[30%]'>
                  <img src='/assets/Others/Video-2.svg'></img>
                </div>
                <div className='lg:w-[30%]'>
                  <img src='/assets/Others/world-map 2.svg'></img>
                </div>
               </div>
               <div className='flex flex-col gap-5 lg:gap-0 lg:flex-row justify-between'>
               <div className='lg:w-[30%] flex flex-col gap-1'>
               <p className='text-sm'>Project Full Address  *</p>
               <input  className={`w-[100%] outline-none px-3 py-1  rounded-md shadow-md  ${
                theme === "dark"
                  ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                  : "bg-white text-black border"
              }`}></input>
           </div>
           <div className='lg:w-[30%] flex flex-col gap-1'>
           <p className='text-sm'>Promotors Company  *</p>
           <input placeholder='URl' className={`w-[100%] outline-none px-3 py-1  rounded-md shadow-md  ${
            theme === "dark"
              ? "bg-[#58595b] text-white border border-[#2b2b2b]"
              : "bg-white text-black border"
          }`}></input>
        </div>
        <div className='lg:w-[30%] flex flex-col gap-1'>
        <p className='text-sm'>City  *</p>
        <input placeholder='Insert Google Map URL' className={`w-[100%] outline-none px-3 py-1  rounded-md shadow-md  ${
         theme === "dark"
           ? "bg-[#58595b] text-white border border-[#2b2b2b]"
           : "bg-white text-black border"
       }`}></input>
     </div>
               </div>
               <div className='flex flex-col gap-5 lg:gap-0 lg:flex-row justify-between lg:items-end'>
                  <div className='lg:w-[60%] '>
                 
                  <p className='text-sm'>Project Descrpition  *</p>
                  <textarea  className={`w-[100%] max-h-[150px] overflow-auto outline-none px-3 py-1  rounded-md shadow-md  ${
                   theme === "dark"
                     ? "bg-[#58595b] text-white border border-[#2b2b2b]"
                     : "bg-white text-black border"
                 }`}></textarea>
              </div>
                  <div className='lg:w-[40%]'>
                   <div className='flex justify-center lg:justify-end gap-5'>
                      <button className=" text-black rounded-lg px-8 py-1 bg-[#019444]">Reset</button>
                      <button className=" text-black rounded-lg px-8 py-1 bg-[#019444]">Submit</button>
                   </div>
                  </div>
               </div>
             </div>
        </div>
      </div>
    </div>
  )
}
