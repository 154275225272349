import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'

export default function Sidebar({ theme }) {
    const [Sidebar, setSidebar] = useState(false);
    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);

    const handleSidebar = () => {
        setSidebar(!Sidebar);
    }
    const handleClickOutside = (event) => {
        if (
            (Sidebar && dropdownRef.current && !dropdownRef.current.contains(event.target) && buttonRef.current && !buttonRef.current.contains(event.target))
        ) {
            setSidebar(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [Sidebar]);

    return (
        <div>
            <div className={`hidden lg:block w-[20%] py-5 px-10 min-h-[90vh] ${theme === 'dark' ? 'bg-[#151515] ' : 'bg-[#D9D9D9]'}`}>
                <ul class="space-y-4  font-semibold">
                    <li class={`cursor-pointer ${theme === 'dark' ? 'text-gray-300 hover:text-white ' : 'text-gray-800 hover:text-black'}`}>Dashboard</li>
                    <li class={`cursor-pointer ${theme === 'dark' ? 'text-gray-300 hover:text-white ' : 'text-gray-800 hover:text-black'}`}>User List</li>
                    <li class={`cursor-pointer ${theme === 'dark' ? 'text-gray-300 hover:text-white ' : 'text-gray-800 hover:text-black'}`}>KYC Submission</li>
                    <li class={`cursor-pointer ${theme === 'dark' ? 'text-gray-300 hover:text-white ' : 'text-gray-800 hover:text-black'}`}>Twofa Listing</li>
                    <li class={`cursor-pointer ${theme === 'dark' ? 'text-gray-300 hover:text-white ' : 'text-gray-800 hover:text-black'}`}>Projects</li>
                    <li class={`cursor-pointer ${theme === 'dark' ? 'text-gray-300 hover:text-white ' : 'text-gray-800 hover:text-black'}`}>History</li>
                    <li class={`cursor-pointer ${theme === 'dark' ? 'text-gray-300 hover:text-white ' : 'text-gray-800 hover:text-black'}`}>Wallet</li>
                    <li class={`cursor-pointer ${theme === 'dark' ? 'text-gray-300 hover:text-white ' : 'text-gray-800 hover:text-black'}`}>Admin Support</li>
                    <li class={`cursor-pointer ${theme === 'dark' ? 'text-gray-300 hover:text-white ' : 'text-gray-800 hover:text-black'}`}>CMS</li>
                </ul>
            </div>
            <div className={`absolute block lg:hidden ${theme === 'dark' ? 'text-white' : ''}`}>
                <FontAwesomeIcon icon={faBars} className='py-3 px-5 text-xl' onClick={handleSidebar} ref={buttonRef}/>
            </div>
            {Sidebar && (
                <div className={`absolute left-3 top-[16vh] md:top-[14vh] z-50 px-5 py-3 w-[50%] md:w-auto rounded-md ${theme === 'dark' ? 'bg-[#151515] ' : 'bg-[#D9D9D9]'}`}
                    data-aos='fade-right'
                    data-aos-duration='1000'
                    ref={dropdownRef}
                >
                    <ul class="space-y-4  font-semibold">
                        <li class={`cursor-pointer ${theme === 'dark' ? 'text-gray-300 hover:text-white ' : 'text-gray-800 hover:text-black'}`}>Dashboard</li>
                        <li class={`cursor-pointer ${theme === 'dark' ? 'text-gray-300 hover:text-white ' : 'text-gray-800 hover:text-black'}`}>User List</li>
                        <li class={`cursor-pointer ${theme === 'dark' ? 'text-gray-300 hover:text-white ' : 'text-gray-800 hover:text-black'}`}>KYC Submission</li>
                        <li class={`cursor-pointer ${theme === 'dark' ? 'text-gray-300 hover:text-white ' : 'text-gray-800 hover:text-black'}`}>Twofa Listing</li>
                        <li class={`cursor-pointer ${theme === 'dark' ? 'text-gray-300 hover:text-white ' : 'text-gray-800 hover:text-black'}`}>Projects</li>
                        <li class={`cursor-pointer ${theme === 'dark' ? 'text-gray-300 hover:text-white ' : 'text-gray-800 hover:text-black'}`}>History</li>
                        <li class={`cursor-pointer ${theme === 'dark' ? 'text-gray-300 hover:text-white ' : 'text-gray-800 hover:text-black'}`}>Wallet</li>
                        <li class={`cursor-pointer ${theme === 'dark' ? 'text-gray-300 hover:text-white ' : 'text-gray-800 hover:text-black'}`}>Admin Support</li>
                        <li class={`cursor-pointer ${theme === 'dark' ? 'text-gray-300 hover:text-white ' : 'text-gray-800 hover:text-black'}`}>CMS</li>
                    </ul>
                </div>
            )}
        </div>
    )
}
