import React, { useState } from 'react'
import Header from '../Layout/Header';
import { faLocationDot, faSliders } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select'
import Footer from '../Layout/Footer';


export default function Marketplace() {
    const [theme, setTheme] = useState('light');

    const toggleTheme = () => {
        setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
    };

    const collections = [
        { photo: 'Gold.png', rank: 1, collection: 'Gold 1kg', floorPrice: '< 0.01 ETH', volume: '36 ETH' },
        { photo: 'Gold.png', rank: 2, collection: 'Gold 1kg', floorPrice: '< 0.01 ETH', volume: '36 ETH' },
        { photo: 'Gold.png', rank: 3, collection: 'Gold 1kg', floorPrice: '< 0.01 ETH', volume: '36 ETH' },
        { photo: 'Gold.png', rank: 4, collection: 'Gold 1kg', floorPrice: '< 0.01 ETH', volume: '36 ETH' },
        { photo: 'Gold.png', rank: 5, collection: 'Gold 1kg', floorPrice: '< 0.01 ETH', volume: '36 ETH' },
        { photo: 'Gold.png', rank: 6, collection: 'Gold 1kg', floorPrice: '< 0.01 ETH', volume: '36 ETH' },
        { photo: 'Gold.png', rank: 7, collection: 'Gold 1kg', floorPrice: '< 0.01 ETH', volume: '36 ETH' },
        { photo: 'Gold.png', rank: 8, collection: 'Gold 1kg', floorPrice: '< 0.01 ETH', volume: '36 ETH' },
        { photo: 'Gold.png', rank: 9, collection: 'Gold 1kg', floorPrice: '< 0.01 ETH', volume: '36 ETH' },
        { photo: 'Gold.png', rank: 10, collection: 'Gold 1kg', floorPrice: '< 0.01 ETH', volume: '36 ETH' },
    ];

    const firstHalf = collections.slice(0, 5);
    const secondHalf = collections.slice(5, 10);

    const Estate = [
        { totalprice: '$ 685,665', tokenprice: '$ 50.79', asset: 'building1.png', wealthtype: 'Real Estate', assettype: 'Dubai Deserts', status: 'Available' },
        { totalprice: '$ 685,665', tokenprice: '$ 50.79', asset: 'building3.png', wealthtype: 'Gold', assettype: 'Gold 1kg', status: 'Available' },
        { totalprice: '$ 685,665', tokenprice: '$ 50.79', asset: 'building4.png', wealthtype: 'Real Estate', assettype: 'Dubai Deserts', status: 'Available' },
    ]

    const Commodities = [
        { totalprice: '$ 685,665', tokenprice: '$ 50.79', asset: 'building1.png', wealthtype: 'Real Estate', assettype: 'Dubai Deserts', status: 'Available' },
        { totalprice: '$ 685,665', tokenprice: '$ 50.79', asset: 'building3.png', wealthtype: 'Gold', assettype: 'Gold 1kg', status: 'Available' },
        { totalprice: '$ 685,665', tokenprice: '$ 50.79', asset: 'building4.png', wealthtype: 'Real Estate', assettype: 'Dubai Deserts', status: 'Available' },
    ]

    const Results = [
        { totalprice: '$ 685,665', tokenprice: '$ 50.79', asset: 'building1.png', wealthtype: 'Real Estate', assettype: 'Dubai Deserts', status: 'Available' },
        { totalprice: '$ 685,665', tokenprice: '$ 50.79', asset: 'building3.png', wealthtype: 'Gold', assettype: 'Gold 1kg', status: 'Available' },
        { totalprice: '$ 685,665', tokenprice: '$ 50.79', asset: 'building4.png', wealthtype: 'Real Estate', assettype: 'Dubai Deserts', status: 'Available' },
    ]
    const filters = [
        { totalprice: '$ 685,665', tokenprice: '$ 50.79', asset: 'building1.png', wealthtype: 'Real Estate', assettype: 'Dubai Deserts', status: 'Available' },
        { totalprice: '$ 685,665', tokenprice: '$ 50.79', asset: 'building3.png', wealthtype: 'Gold', assettype: 'Gold 1kg', status: 'Available' },
        { totalprice: '$ 685,665', tokenprice: '$ 50.79', asset: 'building4.png', wealthtype: 'Real Estate', assettype: 'Dubai Deserts', status: 'Available' },
        { totalprice: '$ 685,665', tokenprice: '$ 50.79', asset: 'building5.png', wealthtype: 'Platinum', assettype: 'Platinum 100kg', status: 'Available' },
    ]

    const Duration = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ]
    const Engagement = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ]

    const NFT101 = [
        { photo: 'NFT1.png', question: 'What is an NFT?' },
        { photo: 'NFT2.png', question: 'How to buy an NFT' },
        { photo: 'NFT3.png', question: 'How to create an NFT on OzoneX platform?' },
        { photo: 'NFT1.png', question: 'How to sell an NFT using OzoneX platform?' }
    ]
    const CustomStyle = {
        control: (provided, state) => ({
            ...provided,
            border: '0px',
            backgroundColor: 'transparent',
            boxShadow: '0px',
            color: 'white',
            paddingLeft: '8px',
            outline: 'none',
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: 'black',
            fontSize: '14px',
        }),
        option: (provided, state) => ({
            ...provided,
            textAlign: 'center',
            backgroundColor: state.isFocused ? '#d3d3d3' : 'white',
            color: '#000', // Color of the option text
            ':active': {
                backgroundColor: '#d3d3d3', // Light gray when clicked
            },
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: '#000', // Color of the selected option text
        }),
        input: (provided, state) => ({
            ...provided,
            color: '#fff', // Color of the typed input text
        }),
        menu: (provided, state) => ({
            ...provided,
            background: '#fff', // Color of the typed input text
        }),
    };
    return (
        <div className={`bg-contain bg-center ${theme === 'dark' ? 'bg-black text-white' : 'bg-white text-black'}`}
            style={{
                backgroundImage: `url("/assets/bg/${theme === 'dark' ? 'bgdark.svg' : 'bglight.svg'}")`,
            }}>
            <Header theme={theme} toggleTheme={toggleTheme} />
            <div className='w-[90%] mx-auto'>
                <div className='sec-1 flex flex-col lg:flex-row items-center justify-center'>
                    <div className='w-[100%] lg:w-[50%]'>
                        <img src='/assets/building/cartoonbuilding.png'></img>
                    </div>
                    <div className='w-[100%] lg:w-[30%]'>
                        <h1 className='text-2xl lg:text-3xl font-bold text-center'>Market Place</h1>
                        <div className='flex gap-5 justify-center mt-5'>
                            <button className='px-3 py-1 bg-[#019444] rounded-md'>Single NFT</button>
                            <button className='px-3 py-1 bg-[#019444] rounded-md'>Fractional NFT</button>
                        </div>
                    </div>
                </div>
                <div className="sec-2 pb-10">
                    <div className="flex justify-between items-center mb-5">
                        <h2 className="text-xl font-bold">Trending collections</h2>
                        <button className='px-3 py-1 bg-[#019444] rounded-md'>View all</button>
                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                        <table className="table-auto w-full border-collapse  text-left">
                            <thead>
                                <tr className={`duration-500 border-b ${theme === 'dark' ? 'border-white/50' : 'text-gray-500 border-gray-300'}`}>
                                    <th className=" px-4 py-2">Rank</th>
                                    <th className=" px-4 py-2">Collection</th>
                                    <th className=" px-4 py-2">Floor Price</th>
                                    <th className=" px-4 py-2">Volume</th>
                                </tr>
                            </thead>
                            <tbody>
                                {firstHalf.map((item, index) => (
                                    <tr key={index} className={`duration-500  ${theme === 'dark' ? 'hover:bg-[#151515]' : 'hover:bg-gray-100'}`}>
                                        <td className=" px-4 py-2 text-center">{item.rank}</td>
                                        <td className=" px-4 py-2 flex items-center">
                                            <img
                                                src={`/assets/Others/${item.photo}`}
                                                alt={item.collection}
                                                className="w-10 h-10 rounded mr-3"
                                            />
                                            {item.collection}
                                        </td>
                                        <td className=" px-4 py-2 text-center">{item.floorPrice}</td>
                                        <td className=" px-4 py-2 text-center">{item.volume}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <table className="table-auto w-full border-collapse  text-left">
                            <thead>
                                <tr className={`duration-500 border-b ${theme === 'dark' ? 'border-white/50' : 'text-gray-500 border-gray-300'}`}>
                                    <th className=" px-4 py-2">Rank</th>
                                    <th className=" px-4 py-2">Collection</th>
                                    <th className=" px-4 py-2">Floor Price</th>
                                    <th className=" px-4 py-2">Volume</th>
                                </tr>
                            </thead>
                            <tbody>
                                {secondHalf.map((item, index) => (
                                    <tr key={index} className={`duration-500  ${theme === 'dark' ? 'hover:bg-[#151515]' : 'hover:bg-gray-100'}`}>
                                        <td className=" px-4 py-2 text-center">{item.rank}</td>
                                        <td className=" px-4 py-2 flex items-center">
                                            <img
                                                src={`/assets/Others/${item.photo}`}
                                                alt={item.collection}
                                                className="w-10 h-10 rounded mr-3"
                                            />
                                            {item.collection}
                                        </td>
                                        <td className=" px-4 py-2 text-center">{item.floorPrice}</td>
                                        <td className=" px-4 py-2 text-center">{item.volume}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                </div>
                <div className='sec-3 pb-10'>
                    <div className={`pb-3 mb-5 border-b ${theme === 'dark' ? 'border-white/50' : ''}`}>
                        <h2 className="text-xl font-bold">Real Estate NFTs</h2>
                    </div>
                    <div className='grid md:grid-cols-2 lg:grid-cols-3 gap-5 md:gap-10'>
                        {Estate.map((item, index) => (
                            <div key={index} className={`px-6 py-4 rounded-2xl border ${theme === 'dark' ? 'bg-[#151515] border-[#2B2B2B]' : 'bg-[#F8F8F8] border-[#D9D8D8]'}`}>
                                <div className='flex justify-between font-bold'>
                                    <div>
                                        <h1>TOTAL PRICE</h1>
                                        <p className='text-2xl'>{item.totalprice}</p>
                                    </div>
                                    <div>
                                        <h1>Token Price</h1>
                                        <p className='text-2xl'>{item.tokenprice}</p>
                                    </div>
                                </div>
                                <div className='mt-2 relative'>
                                    <img className='w-full' src={`/assets/building/${item.asset}`}></img>
                                    <div className={`absolute right-0 top-5 px-3 py-1 rounded-l-md ${item.status === 'Available' ? 'bg-[#019444]' : 'bg-[#94010A]'}`}>{item.status}</div>
                                </div>
                                <div className='mt-3 flex justify-between gap-3 items-center text-sm 2xl:text-base'>
                                    <div className='flex gap-3 items-center text-center text-black'>
                                        <div className='bg-[#D9D9D9] rounded-md px-2 py-1 font-semibold '>
                                            Dubai
                                        </div>
                                        <div className='bg-[#D9D9D9] rounded-md px-2 py-1 font-semibold '>
                                            {item.wealthtype}
                                        </div>
                                    </div>
                                    <p className='truncate'><FontAwesomeIcon icon={faLocationDot} className='text-[#ff0000]' /> Capitol Hill Mansion</p>
                                </div>
                                <div className='mt-5'>
                                    <h1 className='font-bold text-lg'>{item.assettype}</h1>
                                    <div className={`flex justify-between items-center border-b py-3 ${theme === 'dark' ? 'border-white/20' : ''}`}>
                                        <div className='text-sm 2xl:text-base'>
                                            <h2 className='font-bold'>Expected Income</h2>
                                            <p className='mt-1'>Not including capital appreciation</p>
                                        </div>
                                        <p className='font-bold text-lg'>4.91%</p>
                                    </div>
                                    <div className={`flex justify-between items-center font-bold border-b py-3 text-sm 2xl:text-base ${theme === 'dark' ? 'border-white/20' : ''}`}>
                                        <h2>Income Start Date</h2>
                                        <p>Nov 24,2024</p>
                                    </div>
                                    <div className='flex justify-between items-center font-bold py-3 text-sm 2xl:text-base'>
                                        <h2>Income per Token</h2>
                                        <p>Nov 24,2024</p>
                                    </div>
                                    <div className='flex justify-center mt-2'>
                                        <button className='bg-[#019444] rounded-md px-3 py-1'>View Property</button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='sec-4 pb-10'>
                    <div className={`pb-3 mb-5 border-b ${theme === 'dark' ? 'border-white/50' : ''}`}>
                        <h2 className="text-xl font-bold">Commodities</h2>
                    </div>
                    <div className='grid md:grid-cols-2 lg:grid-cols-3 gap-5 md:gap-10'>
                        {Commodities.map((item, index) => (
                            <div key={index} className={`px-6 py-4 rounded-2xl border ${theme === 'dark' ? 'bg-[#151515] border-[#2B2B2B]' : 'bg-[#F8F8F8] border-[#D9D8D8]'}`}>
                                <div className='flex justify-between font-bold'>
                                    <div>
                                        <h1>TOTAL PRICE</h1>
                                        <p className='text-2xl'>{item.totalprice}</p>
                                    </div>
                                    <div>
                                        <h1>Token Price</h1>
                                        <p className='text-2xl'>{item.tokenprice}</p>
                                    </div>
                                </div>
                                <div className='mt-2 relative'>
                                    <img className='w-full' src={`/assets/building/${item.asset}`}></img>
                                    <div className={`absolute right-0 top-5 px-3 py-1 rounded-l-md ${item.status === 'Available' ? 'bg-[#019444]' : 'bg-[#94010A]'}`}>{item.status}</div>
                                </div>
                                <div className='mt-3 flex justify-between gap-3 items-center text-sm 2xl:text-base'>
                                    <div className='flex gap-3 items-center text-center text-black'>
                                        <div className='bg-[#D9D9D9] rounded-md px-2 py-1 font-semibold '>
                                            Dubai
                                        </div>
                                        <div className='bg-[#D9D9D9] rounded-md px-2 py-1 font-semibold '>
                                            {item.wealthtype}
                                        </div>
                                    </div>
                                    <p className='truncate'><FontAwesomeIcon icon={faLocationDot} className='text-[#ff0000]' /> Capitol Hill Mansion</p>
                                </div>
                                <div className='mt-5'>
                                    <h1 className='font-bold text-lg'>{item.assettype}</h1>
                                    <div className={`flex justify-between items-center border-b py-3 ${theme === 'dark' ? 'border-white/20' : ''}`}>
                                        <div className='text-sm 2xl:text-base'>
                                            <h2 className='font-bold'>Expected Income</h2>
                                            <p className='mt-1'>Not including capital appreciation</p>
                                        </div>
                                        <p className='font-bold text-lg'>4.91%</p>
                                    </div>
                                    <div className={`flex justify-between items-center font-bold border-b py-3 text-sm 2xl:text-base ${theme === 'dark' ? 'border-white/20' : ''}`}>
                                        <h2>Income Start Date</h2>
                                        <p>Nov 24,2024</p>
                                    </div>
                                    <div className='flex justify-between items-center font-bold py-3 text-sm 2xl:text-base'>
                                        <h2>Income per Token</h2>
                                        <p>Nov 24,2024</p>
                                    </div>
                                    <div className='flex justify-center mt-2'>
                                        <button className='bg-[#019444] rounded-md px-3 py-1'>View Property</button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='sec-5 pb-10'>
                    <div className={`pb-3 mb-5 border-b ${theme === 'dark' ? 'border-white/50' : ''}`}>
                        <div className='flex flex-col md:flex-row justify-between items-center'>
                            <div>
                                <h2 className="text-xl font-bold">Results</h2>
                            </div>
                            <div className='flex flex-col md:flex-row gap-2'>
                                <Select options={Duration} styles={CustomStyle} placeholder='ALL NFT' className='bg-[#d9d9d9]  px-2 rounded-md' />
                                <div className='flex gap-2 items-center justify-between bg-[#d9d9d9]  px-2 rounded-md'><span className='font-bold text-sm text-black'>Sort by :</span>
                                    <Select options={Engagement} styles={CustomStyle} placeholder='Recently added' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='grid md:grid-cols-2 lg:grid-cols-3 gap-5 md:gap-10'>
                        {Results.map((item, index) => (
                            <div key={index} className={`px-6 py-4 rounded-2xl border ${theme === 'dark' ? 'bg-[#151515] border-[#2B2B2B]' : 'bg-[#F8F8F8] border-[#D9D8D8]'}`}>
                                <div className='flex justify-between font-bold'>
                                    <div>
                                        <h1>TOTAL PRICE</h1>
                                        <p className='text-2xl'>{item.totalprice}</p>
                                    </div>
                                    <div>
                                        <h1>Token Price</h1>
                                        <p className='text-2xl'>{item.tokenprice}</p>
                                    </div>
                                </div>
                                <div className='mt-2 relative'>
                                    <img className='w-full' src={`/assets/building/${item.asset}`}></img>
                                    <div className={`absolute right-0 top-5 px-3 py-1 rounded-l-md ${item.status === 'Available' ? 'bg-[#019444]' : 'bg-[#94010A]'}`}>{item.status}</div>
                                </div>
                                <div className='mt-3 flex justify-between gap-3 items-center text-sm 2xl:text-base'>
                                    <div className='flex gap-3 items-center text-center text-black'>
                                        <div className='bg-[#D9D9D9] rounded-md px-2 py-1 font-semibold '>
                                            Dubai
                                        </div>
                                        <div className='bg-[#D9D9D9] rounded-md px-2 py-1 font-semibold '>
                                            {item.wealthtype}
                                        </div>
                                    </div>
                                    <p className='truncate'><FontAwesomeIcon icon={faLocationDot} className='text-[#ff0000]' /> Capitol Hill Mansion</p>
                                </div>
                                <div className='mt-5'>
                                    <h1 className='font-bold text-lg'>{item.assettype}</h1>
                                    <div className={`flex justify-between items-center border-b py-3 ${theme === 'dark' ? 'border-white/20' : ''}`}>
                                        <div className='text-sm 2xl:text-base'>
                                            <h2 className='font-bold'>Expected Income</h2>
                                            <p className='mt-1'>Not including capital appreciation</p>
                                        </div>
                                        <p className='font-bold text-lg'>4.91%</p>
                                    </div>
                                    <div className={`flex justify-between items-center font-bold border-b py-3 text-sm 2xl:text-base ${theme === 'dark' ? 'border-white/20' : ''}`}>
                                        <h2>Income Start Date</h2>
                                        <p>Nov 24,2024</p>
                                    </div>
                                    <div className='flex justify-between items-center font-bold py-3 text-sm 2xl:text-base'>
                                        <h2>Income per Token</h2>
                                        <p>Nov 24,2024</p>
                                    </div>
                                    <div className='flex justify-center mt-2'>
                                        <button className='bg-[#019444] rounded-md px-3 py-1'>View Property</button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='sec-6 pb-10'>
                    <div className={`pb-3 mb-5 border-b ${theme === 'dark' ? 'border-white/50' : 'border-black/20'}`}>
                        <h2 className="text-xl font-bold"><FontAwesomeIcon icon={faSliders} /> Filters</h2>
                    </div>
                    <div className='flex flex-col lg:flex-row gap-5 md:gap-10'>
                        <div className='w-[100%] lg:w-1/3'>
                            <div className="grid md:grid-cols-2 lg:grid-cols-1 content-around gap-10 h-full w-[80%] mx-auto">
                                <div>
                                    <div className="border border-[#D9D8D8] rounded-lg p-4 lg:p-8 bg-[#F8F8F8]">
                                        <h3 className="text-base font-semibold mb-4">Type</h3>
                                        <div className="space-y-4">
                                            <div className="flex items-center border border-[#929292] rounded-full px-3 py-1">
                                                <input
                                                    type="radio"
                                                    className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                                                />
                                                <label className="ml-2 text-sm font-medium text-gray-700">
                                                    All
                                                </label>
                                            </div>
                                            <div className="flex items-center border border-[#929292] rounded-full px-3 py-1">
                                                <input
                                                    type="radio"
                                                    className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                                                />
                                                <label className="ml-2 text-sm font-medium text-gray-700">
                                                    Buy Now
                                                </label>
                                            </div>
                                            <div className="flex items-center border border-[#929292] rounded-full px-3 py-1">
                                                <input
                                                    type="radio"
                                                    className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                                                />
                                                <label className="ml-2 text-sm font-medium text-gray-700">
                                                    Auction
                                                </label>
                                            </div>
                                            <div className="flex items-center border border-[#929292] rounded-full px-3 py-1">
                                                <input
                                                    type="radio"
                                                    className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                                                />
                                                <label className="ml-2 text-sm font-medium text-gray-700">
                                                    Fractional
                                                </label>
                                            </div>
                                            <div className="flex items-center border border-[#929292] rounded-full px-3 py-1">
                                                <input
                                                    type="radio"
                                                    className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                                                />
                                                <label className="ml-2 text-sm font-medium text-gray-700">
                                                    Unlockable Content
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="border border-[#D9D8D8] rounded-lg p-4 lg:p-8 bg-[#F8F8F8]">
                                    <div className="space-y-4">
                                        <div className="flex items-center border border-[#929292] rounded-full px-3 py-1">
                                            <input
                                                type="radio"
                                                className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                                            />
                                            <label htmlFor="polygon" className="ml-2 text-sm font-medium text-gray-700">
                                                All
                                            </label>
                                        </div>
                                        <div className="flex items-center border border-[#929292] rounded-full px-3 py-1">
                                            <input
                                                type="radio"
                                                className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                                            />
                                            <label className="ml-2 text-sm font-medium text-gray-700">
                                                Real Estate
                                            </label>
                                        </div>
                                        <div className="flex items-center border border-[#929292] rounded-full px-3 py-1">
                                            <input
                                                type="radio"
                                                className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                                            />
                                            <label htmlFor="polygon" className="ml-2 text-sm font-medium text-gray-700">
                                                Mall
                                            </label>
                                        </div>
                                        <div className="flex items-center border border-[#929292] rounded-full px-3 py-1">
                                            <input
                                                type="radio"
                                                className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                                            />
                                            <label className="ml-2 text-sm font-medium text-gray-700">
                                                Resort
                                            </label>
                                        </div>
                                        <div className="flex items-center border border-[#929292] rounded-full px-3 py-1">
                                            <input
                                                type="radio"
                                                className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                                            />
                                            <label htmlFor="polygon" className="ml-2 text-sm font-medium text-gray-700">
                                                Agriculture
                                            </label>
                                        </div>
                                        <div className="flex items-center border border-[#929292] rounded-full px-3 py-1">
                                            <input
                                                type="radio"
                                                className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                                            />
                                            <label className="ml-2 text-sm font-medium text-gray-700">
                                                Gold
                                            </label>
                                        </div>
                                        <div className="flex items-center border border-[#929292] rounded-full px-3 py-1">
                                            <input
                                                type="radio"
                                                className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                                            />
                                            <label htmlFor="polygon" className="ml-2 text-sm font-medium text-gray-700">
                                                Silver
                                            </label>
                                        </div>
                                        <div className="flex items-center border border-[#929292] rounded-full px-3 py-1">
                                            <input
                                                type="radio"
                                                className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                                            />
                                            <label className="ml-2 text-sm font-medium text-gray-700">
                                                Aluminium
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="border border-[#D9D8D8] rounded-lg p-4 lg:p-8 bg-[#F8F8F8]">
                                        <h3 className="text-base font-semibold mb-4">Minted</h3>
                                        <div className="space-y-4">
                                            <div className="flex items-center border border-[#929292] rounded-full px-3 py-1">
                                                <input
                                                    type="radio"
                                                    className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                                                />
                                                <label htmlFor="polygon" className="ml-2 text-sm font-medium text-gray-700">
                                                    Polygon
                                                </label>
                                            </div>
                                            <div className="flex items-center border border-[#929292] rounded-full px-3 py-1">
                                                <input
                                                    type="radio"
                                                    className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                                                />
                                                <label className="ml-2 text-sm font-medium text-gray-700">
                                                    Ethereum
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="border border-[#D9D8D8] rounded-lg p-4 lg:p-8 bg-[#F8F8F8]">
                                        <h3 className="text-base font-semibold mb-4">Verification</h3>
                                        <div className="space-y-4">
                                            <div className="flex items-center border border-[#929292] rounded-full px-3 py-1">
                                                <input
                                                    type="radio"
                                                    className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                                                />
                                                <label htmlFor="polygon" className="ml-2 text-sm font-medium text-gray-700">
                                                    All
                                                </label>
                                            </div>
                                            <div className="flex items-center border border-[#929292] rounded-full px-3 py-1">
                                                <input
                                                    type="radio"
                                                    className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                                                />
                                                <label className="ml-2 text-sm font-medium text-gray-700">
                                                    Verified
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='w-[100%] lg:w-2/3'>
                            <div className='grid md:grid-cols-2 gap-5 md:gap-10'>
                                {filters.map((item, index) => (
                                    <div key={index} className={`px-6 py-4 rounded-2xl border ${theme === 'dark' ? 'bg-[#151515] border-[#2B2B2B]' : 'bg-[#F8F8F8] border-[#D9D8D8]'}`}>
                                        <div className='flex justify-between font-bold'>
                                            <div>
                                                <h1>TOTAL PRICE</h1>
                                                <p className='text-2xl'>{item.totalprice}</p>
                                            </div>
                                            <div>
                                                <h1>Token Price</h1>
                                                <p className='text-2xl'>{item.tokenprice}</p>
                                            </div>
                                        </div>
                                        <div className='mt-2 relative'>
                                            <img className='w-full' src={`/assets/building/${item.asset}`}></img>
                                            <div className={`absolute right-0 top-5 px-3 py-1 rounded-l-md ${item.status === 'Available' ? 'bg-[#019444]' : 'bg-[#94010A]'}`}>{item.status}</div>
                                        </div>
                                        <div className='mt-3 flex justify-between gap-3 items-center text-sm 2xl:text-base'>
                                            <div className='flex gap-3 items-center text-center text-black'>
                                                <div className='bg-[#D9D9D9] rounded-md px-2 py-1 font-semibold '>
                                                    Dubai
                                                </div>
                                                <div className='bg-[#D9D9D9] rounded-md px-2 py-1 font-semibold '>
                                                    {item.wealthtype}
                                                </div>
                                            </div>
                                            <p className='truncate'><FontAwesomeIcon icon={faLocationDot} className='text-[#ff0000]' /> Capitol Hill Mansion</p>
                                        </div>
                                        <div className='mt-5'>
                                            <h1 className='font-bold text-lg'>{item.assettype}</h1>
                                            <div className={`flex justify-between items-center border-b py-3 ${theme === 'dark' ? 'border-white/20' : ''}`}>
                                                <div className='text-sm 2xl:text-base'>
                                                    <h2 className='font-bold'>Expected Income</h2>
                                                    <p className='mt-1'>Not including capital appreciation</p>
                                                </div>
                                                <p className='font-bold text-lg'>4.91%</p>
                                            </div>
                                            <div className={`flex justify-between items-center font-bold border-b py-3 text-sm 2xl:text-base ${theme === 'dark' ? 'border-white/20' : ''}`}>
                                                <h2>Income Start Date</h2>
                                                <p>Nov 24,2024</p>
                                            </div>
                                            <div className='flex justify-between items-center font-bold py-3 text-sm 2xl:text-base'>
                                                <h2>Income per Token</h2>
                                                <p>Nov 24,2024</p>
                                            </div>
                                            <div className='flex justify-center mt-2'>
                                                <button className='bg-[#019444] rounded-md px-3 py-1'>View Property</button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='sec-7 pb-10'>
                    <div className="flex justify-between items-center mb-5">
                        <h2 className="text-xl font-bold">NFT 101</h2>
                        <button className='px-3 py-1 bg-[#019444] rounded-md'>Learn more</button>
                    </div>
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10'>
                        {NFT101.map((item, index) => (
                            <div key={index} className='rounded-xl bg-[#F8F8F8] p-4 border border-[#D9D8D8]'>
                                <img src={`/assets/building/${item.photo}`}></img>
                                <p className='mt-3 font-bold truncate'>{item.question}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Footer theme={theme} toggleTheme={toggleTheme}/>
        </div>
    )
}